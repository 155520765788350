import { Button, Flex } from '@chakra-ui/react'
import React from 'react'
import { colors } from 'shared/style/colors'

const BaseControls = ({
  handleUpdateBalance,
  triggerDelete,
  isApplyDisabled,
}) => {
  return (
    <Flex gap='23px'>
      <Button
        variant='defaultButton'
        onClick={handleUpdateBalance}
        isDisabled={isApplyDisabled}
        w='204px'
      >
        Apply
      </Button>

      <Button
        variant='defaultButton'
        onClick={triggerDelete}
        w='204px'
        bg={colors['light-text-text-danger']}
        _hover={{
          bg: `${colors['light-red-700']} !important`,
        }}
      >
        Delete
      </Button>
    </Flex>
  )
}

export default BaseControls
