/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Heading, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { colors } from 'shared/style/colors'
import BackButton from '../BackButton'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import axios from 'axios'
import { isEmpty, isEqual } from 'lodash'
import Email from '../Email'
import Coins from '../Coins'
import BaseControls from '../BaseControls'
import UserId from '../UserId'
import RemoveUserModal from '../RemoveUserModal'
import CopyFrom from '../CopyFrom'
import UnlockContent from '../UnlockContent'

const Layout = () => {
  const { user_id } = useParams()
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [copyUser, setCopyUser] = useState(null)
  const [coins, setCoins] = useState(0)
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleBack = useCallback(() => {
    navigate('/webapp')
  }, [])

  const handleDeleteUser = useCallback(async () => {
    onClose()
    setLoading(true)

    const { data } = await axios.post(
      `${process.env.REACT_APP_WEB_APP_URL}/user/delete`,
      { user_id: user?.user_id },
      {
        headers: {
          'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
        },
      },
    )

    if (data.rows === 1) {
      toast({
        title: 'Deleted',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
      handleBack()
    } else {
      toast({
        title: 'Error',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
    }

    setLoading(false)
  }, [user])

  const handleUpdateBalance = useCallback(async () => {
    onClose()
    setLoading(true)

    const { data } = await axios.post(
      `${process.env.REACT_APP_WEB_APP_URL}/user/balance-update`,
      { user_id: user?.user_id, amount: coins },
      {
        headers: {
          'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
        },
      },
    )

    if (data.result) {
      toast({
        title: 'Updated',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      })
      setUser(curr => ({
        ...curr,
        coin_balance: coins,
      }))
    } else {
      toast({
        title: 'Error',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      })
    }

    setLoading(false)
  }, [user, coins])

  const handleInput = useCallback(e => {
    setCoins(Number(e.target.value.replace(/[^\d]/g, '')))
  }, [])

  const handleLoadUser = useCallback(async () => {
    setLoading(true)

    const { data } = await axios.post(
      `${process.env.REACT_APP_WEB_APP_URL}/user`,
      { user_id },
      {
        headers: {
          'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
        },
      },
    )

    if (!isEmpty(data.user)) {
      setUser(data.user)
      setCoins(data.user.coin_balance)
    } else {
      handleBack()
    }

    setLoading(false)
  }, [user_id])

  const handleLoadUserToCopy = useCallback(
    async ({ email, user_id }) => {
      setLoading(true)

      const { data } = await axios.post(
        `${process.env.REACT_APP_WEB_APP_URL}/user/user-to-copy`,
        { user_id, email },
        {
          headers: {
            'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
          },
        },
      )

      setCopyUser(!isEmpty(data.user) ? data.user : null)

      setLoading(false)
    },
    [user_id],
  )

  const handleCopyData = useCallback(async () => {
    setLoading(true)

    if (!isEmpty(user) && !isEmpty(copyUser)) {
      const { data } = await axios.post(
        `${process.env.REACT_APP_WEB_APP_URL}/user/transfer`,
        { from: copyUser.user_id, to: user.user_id },
        {
          headers: {
            'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
          },
        },
      )

      if (data.lastReadResult) {
        toast({
          title: 'Last Reads Updated',
          status: 'success',
          isClosable: true,
          position: 'top-right',
        })
      } else {
        toast({
          title: 'Last Reads Error',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        })
      }

      if (data.transactionsResult) {
        toast({
          title: 'Transactions Updated',
          status: 'success',
          isClosable: true,
          position: 'top-right',
        })
      } else {
        toast({
          title: 'Transactions Error',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        })
      }

      await handleLoadUser()
    }

    setLoading(false)
  }, [user, handleLoadUser, copyUser])

  const handleUpdateLastReadData = useCallback(
    async (user_id, book_id, chapter_id) => {
      setLoading(true)

      await axios.post(
        `${process.env.REACT_APP_WEB_APP_URL}/content/last-read`,
        { user_id, book_id, chapter_id },
        {
          headers: {
            'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
          },
        },
      )

      await handleLoadUser()

      setLoading(false)
    },
    [handleLoadUser],
  )

  const handleUnlockChapter = useCallback(
    async (user_id, book_id, chapter_id) => {
      setLoading(true)

      await axios.post(
        `${process.env.REACT_APP_WEB_APP_URL}/content/unlock`,
        { user_id, book_id, chapter_id, transaction_type: 'free chapters' },
        {
          headers: {
            'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
          },
        },
      )

      await handleLoadUser()

      setLoading(false)
    },
    [handleLoadUser],
  )

  const handleLockChapter = useCallback(
    async (user_id, book_id, chapter_id) => {
      setLoading(true)

      await axios.post(
        `${process.env.REACT_APP_WEB_APP_URL}/content/lock`,
        { user_id, book_id, chapter_id },
        {
          headers: {
            'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
          },
        },
      )

      await handleLoadUser()

      setLoading(false)
    },
    [handleLoadUser],
  )

  const handleUnlockBook = useCallback(
    async (user_id, book_id) => {
      setLoading(true)

      await axios.post(
        `${process.env.REACT_APP_WEB_APP_URL}/content/unlock-story`,
        { user_id, book_id },
        {
          headers: {
            'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
          },
        },
      )

      await handleLoadUser()

      setLoading(false)
    },
    [handleLoadUser],
  )

  const handleLockBook = useCallback(
    async (user_id, book_id) => {
      setLoading(true)

      await axios.post(
        `${process.env.REACT_APP_WEB_APP_URL}/content/lock-story`,
        { user_id, book_id },
        {
          headers: {
            'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
          },
        },
      )

      await handleLoadUser()

      setLoading(false)
    },
    [handleLoadUser],
  )

  const handleClearLastReadData = useCallback(
    async (user_id, book_id) => {
      setLoading(true)

      await axios.post(
        `${process.env.REACT_APP_WEB_APP_URL}/content/remove-last-read`,
        { user_id, book_id },
        {
          headers: {
            'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
          },
        },
      )

      await handleLoadUser()

      setLoading(false)
    },
    [handleLoadUser],
  )

  useEffect(() => {
    handleLoadUser()
  }, [])

  return (
    <Flex h='100%' w='100%' p='10px' gap='20px' direction='column'>
      <Flex h='100%' w='100%' direction='column' gap='12px' overflow='hidden'>
        <Flex
          w='100%'
          maxH={`calc(100vH - 38px)`}
          flex={1}
          border={`1px solid ${colors['primary-gray-10']}`}
          bg={colors.white}
          borderRadius='10px'
          direction='column'
          p='10px'
          gap='12px'
        >
          {loading && <LoadingSpinner />}

          <Flex gap='12px' alignSelf='flex-start' p='12px'>
            <BackButton onClick={handleBack} />

            <Heading size='poppins28' variant='primary-black-text'>
              Users
            </Heading>
          </Flex>

          <Flex
            w='100%'
            border={`1px solid ${colors['primary-gray-10']}`}
            bg={colors.white}
            borderRadius='10px'
            direction='column'
            p='10px'
            gap='12px'
          >
            <UserId user_id={user?.user_id} />
            <Email email={user?.email} />
            <Coins coins={coins} handleInput={handleInput} />
            <BaseControls
              isApplyDisabled={isEqual(coins, user?.coin_balance)}
              triggerDelete={onOpen}
              handleUpdateBalance={handleUpdateBalance}
            />
          </Flex>

          <CopyFrom
            handleLoadUserToCopy={handleLoadUserToCopy}
            copyUser={copyUser}
            handleCopyData={handleCopyData}
          />

          <UnlockContent
            user={user}
            handleClearLastReadData={handleClearLastReadData}
            handleUpdateLastReadData={handleUpdateLastReadData}
            handleUnlockChapter={handleUnlockChapter}
            handleLockChapter={handleLockChapter}
            handleUnlockBook={handleUnlockBook}
            handleLockBook={handleLockBook}
          />
        </Flex>
      </Flex>

      {isOpen && (
        <RemoveUserModal
          isOpen={isOpen}
          onClose={onClose}
          email={user?.email}
          submit={handleDeleteUser}
        />
      )}
    </Flex>
  )
}

export default Layout
